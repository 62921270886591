<template>
<v-container v-if="vedi_menu" height="1000">
  <div class="centerDiv" style="heigth: 100%; width: 100%; padding-top: 10px">

    <v-container class="pl-4" > 
      <v-parallax
          src="http://localhost:8080/Top.jpg"
          height="280"
      >

        <v-row>  
            <v-col cols="3" class="pa-1">
              <v-card class="pa-4 mt-5"> 
                    <img
                      style="height: auto; width: 200px"
                      v-if="logo_azienda != 'undefined'"
                      :src="logo_azienda"
                      alt="Quickview"
                    />
                    <img
                      style="height: auto; width: 200px"
                      v-else
                      src="../../../assets/logo2.png"
                      alt="Quickview"
                    />          
                  <h1 style="color: #03a9f4; font-weight: normal" :key="testo_azienda">
                    {{ testo_azienda }}
                  </h1>
              </v-card>
            </v-col>     
        </v-row>
      </v-parallax>
    </v-container>
    <v-row>
        <v-col cols="12" align="center">
            <h1 style="color: black; font-weight: bold" > Voci  di Menù </h1>
        </v-col>      
    </v-row>
    <br>
    <v-divider inset> </v-divider>
    <br>

    <v-container class="pa-4 text-center ">
      <v-row
        class="fill-height"
        align="center"
        justify="left"
      >

        <template v-for="(item, id_modulo) in menu">
          <v-col
            :key="id_modulo"
            cols="12"
            md="4"
          >
            <v-hover v-slot="{ hover }"
              open-delay="50"
            >
              
              <v-card 
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                height="200"
                rounded="3"
                outlined
              >
                <v-row>
                  <v-col cols="8">
                    <v-card-title>
                          {{ item.text }}
                    </v-card-title>
                        <v-divider ></v-divider>
                    <v-card-title>
                        <div>
                          <p class="ma-0 text-body-1 font-weight-bold  text-left">
                            Quality - ERP
                          </p>
                          <p class="text-caption font-weight-medium  text-left">
                            Menù
                          </p>
                          <p>
                          <v-btn
                            outlined
                            color="primary"
                            @click="apriModulo(item)"
                          >
                            <v-icon>mdi-format-list-bulleted-square</v-icon>
                            Procedi
                          </v-btn> 
                          </p>                          
                        </div>
                    </v-card-title> 
                  
    

                  </v-col>                
                  <v-col cols="3" >
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-btn 
                          color="white"
                          x-large
                          elevation="0"
                          @click="apriModulo(item)"
                        >
                          <v-icon  size=80px color="primary"
                          >{{ item.icon }}</v-icon>
                        </v-btn>
                      </v-row>  
                    </v-container> 
                  </v-col>
                  <v-col cols="1" />
  
                </v-row>  


              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog_iframe"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click="dialog_iframe = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe
          :src="link"
          style="display: block; height: 100vh"
          width="100%"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</v-container>


</template>

<style scoped>
.bkground {
  background: url('../../../assets/Top.jpg');
  background-size: cover;
  height: 100vh;
}
</style>
<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    link: "",
    moduli_dx: [],
    menu: [],
    nome_utente: localStorage.nominativo,
    id_azienda: localStorage.id_azienda,
    dialog_iframe: false,
    logo_azienda: localStorage.img_azienda,
    testo_azienda: "",
    vedi_menu: false,
    imageSrc: '@/assets/Top.jpg',

      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
 
      transparent: 'rgba(255, 255, 255, 0)',

  }),
  created() { 
    

    if (localStorage.menu !== "undefined")
      this.menu = JSON.parse(localStorage.menu);
      
 
      
  },
  mounted() {

    if (localStorage.menu !== "undefined")
      this.menu = JSON.parse(localStorage.menu);

    //Istruzione che controlla se ho una sola voce di menu ed il profilo è maggiore di 1 e 2 ovvero super user e eamministratore quindi accedo direttamante alla pagina.
    if( this.menu.length == 1 && localStorage.id_profilo > 2) {
        this.vedi_menu = false
        this.apriModulo(this.menu[0])
        
    }   
    else this.vedi_menu = true
    this.testo_azienda = this.$store.state.testo_azienda;
  },
  
  watch: {
    "$store.state.testo_azienda": function () {
      this.testo_azienda = this.$store.state.testo_azienda;
    },
  },
  methods: {

    apriModulo(item) {

      if (item.path != null) {
        // this.dialog_iframe = true;
        this.link = item.path;
        this.$router.push(this.link);
      } 
    },
 

  },
};
</script>
<style scoped></style>
